import BandAidEditor from 'components/BandAidEditor'
import Button from 'components/Button'
import TestActionButtons from 'components/TestActionButtons'
import TestNavigationButtons from 'components/TestNavigationButtons'
import { AppType, useAppType } from 'providers/AppTypeProvider'
import { useBandAidData } from 'providers/BandAidProvider'
import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useIdle } from 'react-use'

interface Props extends RouteComponentProps<{ testState: 'introduction' | 'active' }> {}

const TestRoute: FC<Props> = ({ match: { params }, history }) => {
  const testState = params.testState

  const { mode, setMode } = useBandAidData()
  const { search } = useLocation()
  const handleStartTest = () => history.push('/tool/active' + search)

  useEffect(() => {
    if (testState === 'active' && mode === 'INTRODUCTION') {
      setMode('ACTIVE')
    } else if (testState === 'introduction' && mode !== 'INTRODUCTION') {
      setMode('INTRODUCTION')
    } else if (mode == null) {
      setMode('ACTIVE')
    }
    // eslint-disable-next-line
  }, [testState, mode])

  const appType = useAppType()
  const isIdle = useIdle(120 * 1000)
  const { reset } = useBandAidData()

  useEffect(() => {
    if (isIdle && appType === AppType.Exhibition) {
      reset()
      history.replace('/tool/introduction' + search)
    }

    // eslint-disable-next-line
  }, [isIdle])

  if (!mode) {
    return <div />
  }

  return (
    <div
      className='absolute inset-0 oberflow-hidden bg-center bg-cover'
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/test_bg.jpg'})` }}
    >
      <BandAidEditor />
      <div
        className='absolute inset-0 overflow-hidden flex transition-opacity duration-500'
        style={{ opacity: mode === 'INTRODUCTION' ? 1 : 0, pointerEvents: mode === 'INTRODUCTION' ? 'all' : 'none' }}
      >
        <div className='flex-1 flex items-center justify-end'>
          <div className='p-6 max-w-xl'>
            <h1 className='tracking-tight text-right text-5xl leading-tight'>Match pressure point with the optimal product</h1>
            <div className='prose prose-lg text-gray-500 text-xl mb-6 lg:max-w-none lg:mb-0 mt-4 text-right'>
              <p>
                Choosing the most appropriate dressing for the job is important to both prevention and management of pressure ulcers. Click
                below to get started using our dressing selection tool.
              </p>
            </div>
            <div className='flex justify-end'>
              <Button onClick={handleStartTest} className='mt-6' size='xl'>
                I am ready!
              </Button>
            </div>
          </div>
        </div>
        <div className='flex-1' />
      </div>
      <TestNavigationButtons />
      <TestActionButtons show={mode === 'ACTIVE'} />
    </div>
  )
}

export default TestRoute

import BandAidDropzone from 'components/BandAidDropzone'
import Button from 'components/Button'
import LinkWithQuery from 'components/LinkWithQuery'
import TestActionButtons from 'components/TestActionButtons'
import { useBandAidData } from 'providers/BandAidProvider'
import React, { FC } from 'react'

const YourSelectionsRoute: FC = () => {
  const { bodyScale, bandAidDropzones, bandAids } = useBandAidData()

  return (
    <div
      className='absolute inset-0 bg-center bg-cover flex'
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/test_bg.jpg'})` }}
    >
      <div className='flex-1 flex items-center justify-end'>
        <div className='relative' style={{ width: 350 * bodyScale }}>
          <img src={process.env.PUBLIC_URL + '/images/body_front.png'} className='w-full' alt='Body front' />
          {bandAidDropzones
            .filter((dropzone) => dropzone.side === 'FRONT')
            .map((dropzone) => {
              if (dropzone.placedBandAidId) {
                return <BandAidDropzone disableDelete={true} showPressurePoint={true} key={dropzone.id} dropzone={dropzone} />
              } else {
                return null
              }
            })}
        </div>
      </div>
      <div className='max-w-md w-full flex-none flex flex-col items-center justify-center'>
        <h1 className='text-5xl leading-tight text-center mb-12 mt-12'>Your selections</h1>
        <div className='w-64 flex-center bg-white border border-green-500  relative p-3'>
          <div id='mepilex_border_flex_oval_left' className='absolute left-0' />
          <div id='mepilex_border_flex_oval_right' className='absolute right-0' />
          <div className='h-full absolute right-0' />
          {bandAids
            .filter((b) => b.id === 'mepilex_border_flex_oval' || b.id === 'mepilex_border_flex')
            .map((b) => (
              <div key={b.id} className='flex-1 flex flex-col items-center text-center mx-2'>
                <img className='w-24' src={b.image} alt='Mepilex' />
                <h2 style={{ height: 60 }} className='text-sm font-medium text-primary-green'>
                  {b.name}
                </h2>
              </div>
            ))}
        </div>

        <div className='h-8' />

        <div className='w-30 p-2 bg-white border flex-center border-green-500 relative'>
          <div id='mepilex_border_sacrum_right' className='absolute right-0 bg-red-500' />
          {bandAids
            .filter((b) => b.id === 'mepilex_border_sacrum')
            .map((b) => (
              <div key={b.id} className='flex-1 flex flex-col items-center text-center mx-2'>
                <img className='w-24' src={b.image} alt='Mepilex' />
                <h2 className='text-sm font-medium text-primary-green'>{b.name}</h2>
              </div>
            ))}
        </div>

        <div className='h-8' />

        <div className='w-30  p-2 bg-white border flex-center border-green-500  relative'>
          <div id='mepilex_border_heel_right' className='absolute right-0' />
          {bandAids
            .filter((b) => b.id === 'mepilex_border_heel')
            .map((b) => (
              <div key={b.id} className='flex-1 flex flex-col items-center text-center mx-2'>
                <img className='w-24' src={b.image} alt='Mepilex' />
                <h2 className='text-sm font-medium text-primary-green'>{b.name}</h2>
              </div>
            ))}
        </div>
        <div className='h-64' />
      </div>
      <div className='flex-1 flex items-center justify-start'>
        <div className='relative' style={{ width: 350 * bodyScale }}>
          <img src={process.env.PUBLIC_URL + '/images/body_back.png'} className='w-full' alt='Body front' />
          {bandAidDropzones
            .filter((dropzone) => dropzone.side === 'BACK')
            .map((dropzone) => {
              if (dropzone.placedBandAidId) {
                return <BandAidDropzone disableDelete={true} showPressurePoint={true} key={dropzone.id} dropzone={dropzone} />
              } else {
                return null
              }
            })}
        </div>
      </div>

      <TestActionButtons show={true} />
      <div className='absolute bottom-0 right-0 m-4 flex flex-col w-64'>
        <LinkWithQuery to='/tool/active'>
          <Button size='xl' className='w-full' variant='secondary'>
            Back
          </Button>
        </LinkWithQuery>

        <div className='h-2' />
        <LinkWithQuery to='/recommended'>
          <Button size='xl' className='w-full' variant='primary'>
            Next
          </Button>
        </LinkWithQuery>
      </div>
    </div>
  )
}

export default YourSelectionsRoute

import { AppTypeProvider } from 'providers/AppTypeProvider'
import { BandAidProvider } from 'providers/BandAidProvider'
import { HelpSlideOverProvider } from 'providers/HelpSlideOverProvider'
import { NotesSlideOverProvider } from 'providers/NotesSlideOverProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/tailwind.output.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppTypeProvider>
        <BandAidProvider>
          <HelpSlideOverProvider>
            <NotesSlideOverProvider>
              <App />
            </NotesSlideOverProvider>
          </HelpSlideOverProvider>
        </BandAidProvider>
      </AppTypeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { useBandAidData } from 'providers/BandAidProvider'
import { BandAid } from 'providers/bandAids'
import { BandAidDropzone as BandAidDropzoneModel } from 'providers/dropzones'
import React, { FC, useMemo } from 'react'

interface Props {
  dropzone: BandAidDropzoneModel
  showPressurePoint?: boolean
  showPlaceBandAidName?: boolean
  disableDelete?: boolean
  disableBandAid?: boolean
  hide?: boolean
}

const BandAidDropzone: FC<Props> = ({
  dropzone: { position, id, side, placedBandAidId, compatibleBandAidIds },
  showPressurePoint,
  showPlaceBandAidName,
  disableDelete,
  disableBandAid,
  hide,
}) => {
  const { mode, bandAids, bodyScale, showPressurePoints, deleteBandAidPlacement } = useBandAidData()

  const placedBandAid = useMemo<BandAid | null>(() => {
    // if (bandAidPlacement && bandAidPlacement?.dropzone?.id === id) {
    //   return bandAids.find((bandAid) => bandAid.id === bandAidPlacement?.bandAid?.id) || null
    // }
    // return null
    if (placedBandAidId) {
      return bandAids.find((bandAid) => bandAid.id === placedBandAidId) || null
    } else {
      return null
    }
  }, [placedBandAidId, bandAids])

  const handleDeletePlacement = () => {
    if (!disableDelete) {
      deleteBandAidPlacement(id)
    }
  }

  return (
    <>
      <div
        style={{
          transformOrigin: '50% 50%',
          transform: `translate(${position.x * bodyScale}px, ${position.y * bodyScale}px)`,
          width: 48 * bodyScale,
          height: 48 * bodyScale,
          opacity: hide ? 0 : 1,
        }}
        className='absolute top-0 left-0 z-50 flex items-center justify-center transition-all duration-200'
        onClick={handleDeletePlacement}
      >
        {(!placedBandAid || disableBandAid) && (
          <div
            className='absolute border-white border-2 border-dashed rounded-full shadow-xl transition-opacity duration-500'
            style={{
              background: 'rgba(255, 255, 255, 0.2)',
              width: '90%',
              height: '90%',
              transform: 'scale(1.2)',
              transformOrigin: '50% 50%',
              opacity: showPressurePoint || showPressurePoints ? 1 : 0,
            }}
          />
        )}
        {!disableBandAid && (
          <>
            <div
              className={`
                absolute w-full h-full bg-white shadow-xl p-1 rounded-full transition-opacity duration-500 
                ${compatibleBandAidIds.indexOf(placedBandAidId || '') === -1 ? 'border-yellow-300' : 'border-primary-green'}
                ${placedBandAidId && 'border'}
              `}
              style={{ transform: 'scale(2)', transformOrigin: '50% 50%', opacity: placedBandAid ? 1 : 0 }}
            >
              {placedBandAid && <img className='w-full h-full' src={placedBandAid.image} alt='bandaid' />}
              {mode === 'ACTIVE' && !disableDelete && (
                <button
                  onClick={handleDeletePlacement}
                  style={{ top: 0, right: 0, width: 12 * bodyScale, height: 12 * bodyScale }}
                  className='absolute shadow flex-center bg-gray-300 rounded-full'
                >
                  <svg style={{ width: 5, height: 5 }} fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              )}
            </div>
          </>
        )}
        <div id={id} className={`absolute ${id === 'elbow_left' ? 'top-0' : side === 'FRONT' ? 'right-0' : 'left-0'}`} />
      </div>
      {showPlaceBandAidName && (
        <div
          style={{
            transformOrigin: '50% 50%',
            width: 48 * bodyScale,
            transform: `translate(${position.x * bodyScale}px, ${position.y * bodyScale + 48 * bodyScale + 10}px)`,
            fontSize: 10,
          }}
          className={`
            absolute top-0 left-0 z-50 flex items-center justify-center transition-transform leading-tight duration-200 text-center font-semibold
            ${compatibleBandAidIds.indexOf(placedBandAidId || '') === -1 ? 'text-yellow-300' : 'text-primary-green'}
          `}
          onClick={handleDeletePlacement}
        >
          {placedBandAid?.name}
        </div>
      )}
    </>
  )
}

export default BandAidDropzone

import Button from 'components/Button'
import React, { createContext, FC, useContext, useState } from 'react'
import { animated, interpolate, useSpring } from 'react-spring'
import { AppType, useAppType } from './AppTypeProvider'

interface HelpSlideOverProviderValue {
  open: () => void
}

const HelpSlideOverContext = createContext<HelpSlideOverProviderValue>({
  open: () => null,
})

const HelpSlideOverProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const revealProps = useSpring({ opacity: open ? 1 : 0, x: open ? 0 : 500 })

  const appType = useAppType()

  return (
    <HelpSlideOverContext.Provider
      value={{
        open: handleOpen,
      }}
    >
      {children}
      <div
        className={`fixed inset-0 ${!open && 'pointer-events-none'} bg-black transition-opacity duration-500`}
        style={{ opacity: open ? 0.3 : 0, zIndex: 10000 }}
        onClick={handleClose}
      />
      <animated.div
        className={`fixed top-0 bottom-0 p-14 overflow-y-auto right-0 bg-white shadow ${!open && 'pointer-events-none'}`}
        style={{
          zIndex: 10001,
          width: 500,
          transform: interpolate([revealProps.x], (x) => `translate3d(${x}px, 0px, 0)`),
        }}
      >
        <div className='flex justify-end mb-8'>
          <button onClick={handleClose} className='w-12 h-12 text-gray-500 flex-center rounded-full border-gray-500 border'>
            <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
        <h2 className='text-2xl font-bold text-gray-700 mb-8'>How do I use this web tool?</h2>

        <div className='prose prose-lg text-gray-500 mb-12'>
          <p>
            Use the toolbox on the left to drag the desired dressing to a body part, choosing the most appropriate dressing for the job is
            important for a successful management of pressure ulcers. On the final steps in this tool, you will see an overview of your
            pairings, as well as a summary of our recommended usage areas. You can at any time go back and change the selections you have
            made.
          </p>
        </div>
        {appType === AppType.Default && (
          <>
            <div className='text-primary-green text-2xl mb-4'>Discover our recommended pressure ulcer prevention</div>
            <a href='https://www.molnlycke.com/wound-types/pressure-ulcers/' target='_blank' rel='noopener noreferrer'>
              <Button size='xl'>Visit molnlycke.com</Button>
            </a>
          </>
        )}
      </animated.div>
    </HelpSlideOverContext.Provider>
  )
}

const useHelpSlideOver = () => useContext(HelpSlideOverContext)

export { HelpSlideOverProvider, HelpSlideOverContext, useHelpSlideOver }

import { AppType, useAppType } from 'providers/AppTypeProvider'
import { useBandAidData } from 'providers/BandAidProvider'
import React from 'react'
import { animated, useSpring } from 'react-spring'
import { useIdle } from 'react-use'
import Button from './Button'
import LinkWithQuery from './LinkWithQuery'

const TestNavigationButtons = () => {
  const { mode, showPressurePoints, setShowPressurePoints, setBodyRotation, bodyRotation, reset } = useBandAidData()
  const handleToggleShowPressurePoints = () => setShowPressurePoints(!showPressurePoints)
  const handleRotateBody = () => setBodyRotation(bodyRotation === 'BACK' ? 'FRONT' : 'BACK')

  const revealProps = useSpring({ opacity: mode === 'ACTIVE' ? 1 : 0 })

  const isIdle = useIdle(15000)

  const appType = useAppType()

  return (
    <animated.div className='absolute bottom-0 right-0 m-4 flex flex-col' style={{ opacity: revealProps.opacity, width: 280 }}>
      <Button onClick={handleToggleShowPressurePoints} size='xl' variant='third' className={isIdle ? 'blob green' : ''}>
        <svg className='w-5 h-5 mr-2' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
            clipRule='evenodd'
          />
        </svg>
        {!showPressurePoints ? 'Show' : 'Hide'} pressure points
      </Button>

      <div className='h-4' />

      <Button onClick={handleRotateBody} size='xl' variant='third'>
        <svg className='w-5 h-5 mr-2' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            d='M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z'
            clipRule='evenodd'
          />
        </svg>
        Rotate body
      </Button>

      <div className='h-4' />

      <Button onClick={reset} size='xl' variant='third'>
        <svg className='w-5 h-5 mr-2' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
            clipRule='evenodd'
          />
        </svg>
        Clear all selections
      </Button>

      <div className='h-4' />
      {appType === AppType.Default && (
        <LinkWithQuery to='/your-selections' className='w-full'>
          <Button size='xl' className='w-full' variant='primary'>
            I'm done
          </Button>
        </LinkWithQuery>
      )}
    </animated.div>
  )
}

export default TestNavigationButtons

import rotateImg from 'assets/rotate.svg'
import { useBandAidData } from 'providers/BandAidProvider'
import React, { FC, useEffect, useRef } from 'react'
import { animated, config, interpolate, useSpring, useTransition } from 'react-spring'
import BandAidDropzone from './BandAidDropzone'
import BandAidPlacementInformation from './BandAidPlacementInformation'
import BandAidSelectorPanel from './BandAidSelectorPanel'

const BandAidEditor: FC = () => {
  const { mode, bandAidDropzones, bodyRotation, setBodyRotation, setBodyContainerRef, bodyScale } = useBandAidData()
  const handleToggleBodyRotation = () => setBodyRotation(bodyRotation === 'FRONT' ? 'BACK' : 'FRONT')

  const bodyTransitions = useTransition(bodyRotation, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
  })

  const [bodySpring, setBodySpring] = useSpring(() => ({ scale: 0.9, x: mode === 'INTRODUCTION' ? 200 : 0 }))

  useEffect(() => {
    if (mode === 'ACTIVE') {
      setBodySpring({ scale: 1, x: 0 })
    } else if (mode === 'INTRODUCTION') {
      setBodySpring({ scale: 0.9, x: 200 })
      setBodyRotation('FRONT')
    }
    // eslint-disable-next-line
  }, [mode])

  const bodyContainerRef = useRef<any>(null)

  useEffect(() => {
    setBodyContainerRef(bodyContainerRef)
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className='absolute inset-0 overflow-hidden flex items-center justify-center select-none'
      style={{ pointerEvents: mode === 'INTRODUCTION' ? 'none' : 'initial' }}
    >
      <BandAidSelectorPanel />
      <BandAidPlacementInformation />

      {bodyTransitions.map(({ item, key, props }) => {
        if (item === 'FRONT') {
          return (
            <animated.div
              className='relative'
              ref={bodyContainerRef}
              key={key}
              style={{
                width: 350 * bodyScale,
                transform: interpolate([bodySpring.x, bodySpring.scale as any], (x, s) => `translate3d(${x}px, 0px, 0) scale(${s})`),
                ...props,
              }}
            >
              <img src={process.env.PUBLIC_URL + '/images/body_front.png'} className='w-full' alt='body' />
              {bandAidDropzones
                .filter((dropzone) => dropzone.side === 'FRONT')
                .map((dropzone) => (
                  <BandAidDropzone hide={mode === 'INTRODUCTION'} key={dropzone.id} dropzone={dropzone} />
                ))}
            </animated.div>
          )
        } else {
          return (
            <animated.div
              className='relative'
              ref={bodyContainerRef}
              key={key}
              style={{
                width: 350 * bodyScale,
                transform: interpolate([bodySpring.x, bodySpring.scale as any], (x, s) => `translate3d(${x}px, 0px, 0) scale(${s})`),
                ...props,
              }}
            >
              <img src={process.env.PUBLIC_URL + '/images/body_back.png'} className='w-full' alt='body' />
              {bandAidDropzones
                .filter((dropzone) => dropzone.side === 'BACK')
                .map((dropzone) => (
                  <BandAidDropzone hide={mode === 'INTRODUCTION'} key={dropzone.id} dropzone={dropzone} />
                ))}
            </animated.div>
          )
        }
      })}
      <button
        onClick={handleToggleBodyRotation}
        className='absolute mx-auto bottom-0 transition-opacity duration-500'
        style={{ width: 350 * bodyScale, opacity: mode === 'ACTIVE' ? 0.6 : 0, marginBottom: 50 * bodyScale }}
      >
        <img src={rotateImg} alt='rotate' className='w-full' />
      </button>
    </div>
  )
}

export default BandAidEditor

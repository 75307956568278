import React, { DetailedHTMLProps, FC, useMemo } from 'react'

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'third'
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
  className?: string
}

const Button: FC<ButtonProps> = ({ children, size, variant, className, ...rest }) => {
  const baseClass = `flex-center border border-transparent focus:outline-none transition ease-in-out duration-150`

  const variantClass = useMemo(() => {
    if (variant === 'primary' || variant == null) {
      return 'shadow-sm text-white bg-primary-green focus:border-green-700 focus:shadow-outline-green active:bg-green-700 hover:opacity-75'
    }

    if (variant === 'secondary') {
      return 'text-white bg-grey hover:opacity-75 focus:shadow-outline-green active:opacity-75'
    }

    if (variant === 'third') {
      return 'text-gray-700 bg-white hover:opacity-75 focus:shadow-outline-green border border-gray-300'
    }
  }, [variant])

  const sizeClass = useMemo(() => {
    if (size === 'xs') {
      return 'px-2.5 p-1.5 text-xs leading-4'
    }

    if (size === 'sm') {
      return 'px-3 py-2 text-sm leading-5'
    }

    if (size === 'base' || null) {
      return 'px-4 py-2 text-sm leading-5'
    }

    if (size === 'lg') {
      return 'px-4 py-2 text-base leading-6'
    }

    if (size === 'xl') {
      return 'px-6 py-3 text-lg leading-6'
    }
  }, [size])

  return (
    <button {...rest} className={`${className} ${baseClass} ${variantClass} ${sizeClass}`}>
      {children}
    </button>
  )
}

export default Button

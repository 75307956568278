import logo from 'assets/molnlycke.svg'
import LinkWithQuery from 'components/LinkWithQuery'
import { AppType, useAppType } from 'providers/AppTypeProvider'
import React, { FC } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { animated, config, useTransition } from 'react-spring'
import DiscoverRoute from 'routes/DiscoverRoute'
import RecommendedRoute from 'routes/RecommendedRoute'
import StartRoute from 'routes/StartRoute'
import TestRoute from 'routes/TestRoute'
import YourSelectionsRoute from 'routes/YourSelectionsRoute'

const App: FC = () => {
  const location = useLocation()

  const routeTransitions = useTransition(location, (location) => location.pathname.split('/')[1], {
    from: { opacity: 0, transform: 'scale(1.02) translate3d(0, 15px, 0)' }, // transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'scale(1.0) translate3d(0, 0, 0)' }, // transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'scale(1.02) translate3d(0, 15px, 0)' }, //, transform: 'translate3d(-50%,0,0)' },
    config: config.slow,
  })

  const appType = useAppType()

  return (
    <>
      <LinkWithQuery to={appType === AppType.Default ? '/' : '/tool/introduction'}>
        <img src={logo} alt='Logo' className='w-32 xl:w-36 absolute' style={{ top: 30, left: 30, zIndex: 1000 }} />
      </LinkWithQuery>
      {routeTransitions.map(({ item: location, props, key }) => (
        <animated.div className='absolute inset-0 overflow-y-auto z-10' key={key} style={props}>
          <Switch location={location}>
            <Route path='/tool/:testState' component={TestRoute} />
            {appType === AppType.Default && <Route path='/your-selections' component={YourSelectionsRoute} />}
            {appType === AppType.Default && <Route path='/recommended' component={RecommendedRoute} />}
            {appType === AppType.Default && <Route path='/discover' component={DiscoverRoute} />}
            {appType === AppType.Default && <Route path='/' component={StartRoute} />}
            <Redirect to={(appType === AppType.Default ? '/' : '/tool/introduction') + location.search} />
          </Switch>
        </animated.div>
      ))}
    </>
  )
}

export default App

import { AppType, useAppType } from 'providers/AppTypeProvider'
import { useHelpSlideOver } from 'providers/HelpSlideOverProvider'
import { useNoteSlideOver } from 'providers/NotesSlideOverProvider'
import React, { FC } from 'react'
import { animated, useSpring } from 'react-spring'

interface Props {
  show: boolean
}

const TestActionButtons: FC<Props> = ({ show }) => {
  const { open: openHelp } = useHelpSlideOver()
  const { open: openNotes } = useNoteSlideOver()

  const revealProps = useSpring({ opacity: show ? 1 : 0 })

  const appType = useAppType()

  return (
    <animated.div className='absolute top-0 right-0 mr-8 mt-14 flex flex-col items-end' style={{ opacity: revealProps.opacity }}>
      <button onClick={openHelp} className='h-14 text-gray-600 font-medium text-xl flex items-center'>
        Help
        <div className='bg-white shadow h-12 w-12 rounded-full flex-center ml-4'>
          <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
              clipRule='evenodd'
            />
          </svg>
        </div>
      </button>

      {appType === AppType.Default && (
        <button onClick={openNotes} className='h-14 text-gray-600 font-medium text-xl flex items-center mt-2'>
          Notes
          <div className='bg-white shadow h-12 w-12 rounded-full flex-center ml-4'>
            <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
            </svg>
          </div>
        </button>
      )}

      {/* <button onClick={domToImage} className='h-14 text-gray-600 font-medium text-xl flex items-center mt-2'>
        Screenshot
        <div className='bg-white shadow h-12 w-12 rounded-full flex-center ml-4'>
          <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z'
              clipRule='evenodd'
            />
          </svg>
        </div>
      </button> */}
    </animated.div>
  )
}

export default TestActionButtons

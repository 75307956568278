import { useBandAidData } from 'providers/BandAidProvider'
import { BandAidDropzone } from 'providers/dropzones'
import React, { FC, useEffect, useRef, useState } from 'react'
import { animated, config, interpolate, useSpring, useSprings } from 'react-spring'
import { useIdle } from 'react-use'
import { useDrag } from 'react-use-gesture'

const BandAidSelectorPanel: FC = () => {
  const { mode, bodyScale, bandAids, bodyContainerRef, bandAidDropzones, placeBandAid, bodyRotation } = useBandAidData()

  const [springs, setSprings] = useSprings(bandAids.length, (index: number) => ({
    opacity: 1,
    x: 0,
    y: 0,
    scale: 1,
    config: config.stiff,
  }))

  const handleBandAidPlaced = (bandAidIndex: number, placedDropzone: BandAidDropzone) => {
    const bandAid = bandAids[bandAidIndex]
    placeBandAid(bandAid?.id, placedDropzone.id)

    setTimeout(() => {
      const set: any = setSprings
      set((i: number) => ({ opacity: i === bandAidIndex ? 0 : 1, x: 0, y: 0, scale: 1 }))

      setTimeout(() => {
        set((i: number) => ({ opacity: 1, x: 0, y: 0, scale: 1 }))
      }, 500)
    }, 500)
  }

  const bodyBasePosition = useRef<{ x: number; y: number } | null>(null)
  const dragStartPosition = useRef<{ x: number; y: number; width: number; height: number } | null>(null)

  const bind = useDrag(({ args: [index], down, movement, event, initial: [initialX, initialY] }) => {
    if (down && !bodyBasePosition.current) {
      const bandAidPosition = (event?.target as any)?.getBoundingClientRect?.()
      dragStartPosition.current = {
        x: initialX - bandAidPosition.x,
        y: initialY - bandAidPosition.y,
        width: bandAidPosition.width,
        height: bandAidPosition.height,
      }
      bodyBasePosition.current = bodyContainerRef?.current?.getBoundingClientRect().toJSON()
    }

    const set: any = setSprings

    let hoveredDropzone: BandAidDropzone | null = null

    let mx = movement[0]
    let my = movement[1]

    if (dragStartPosition.current) {
      mx = mx + dragStartPosition.current.x - dragStartPosition.current.width / 2
      my = my + dragStartPosition.current.y - dragStartPosition.current.height / 2
    }

    if (bodyBasePosition.current && dragStartPosition.current) {
      const draggedPosition = {
        x: initialX + movement[0],
        y: initialY + movement[1],
      }

      for (const dropzone of bandAidDropzones) {
        if (dropzone.side === bodyRotation && !dropzone.placedBandAidId) {
          const dropzonePosition = {
            x: dropzone.position.x * bodyScale + bodyBasePosition.current.x + 24 * bodyScale,
            y: dropzone.position.y * bodyScale + bodyBasePosition.current.y + 24 * bodyScale,
          }
          const distance = Math.hypot(draggedPosition.x - dropzonePosition.x, draggedPosition.y - dropzonePosition.y)

          if (distance < 50) {
            hoveredDropzone = dropzone
            break
          }
        }
      }
    }

    if (!down) {
      set((i: number) => {
        if (i === index) {
          if (hoveredDropzone && dragStartPosition?.current) {
            handleBandAidPlaced(i, hoveredDropzone)
            return { opacity: 0, y: my, x: mx, scale: 0.6 }
          } else {
            return { opacity: 1, y: 0, x: 0, scale: 1.0 }
          }
        } else {
          return { opacity: 1, y: 0, x: 0, scale: 1.0 }
        }
      })

      bodyBasePosition.current = null
    } else {
      set((i: number) => {
        if (i === index && down) {
          if (hoveredDropzone) {
            return { opacity: 1, y: my, x: mx, scale: 0.6 }
          } else {
            return { opacity: 1, y: my, x: mx, scale: 1.5 }
          }
        } else {
          return { opacity: 1, y: 0, x: 0, scale: 1.0 }
        }
      })
    }
  })

  const revealProps = useSpring({ opacity: mode === 'ACTIVE' ? 1 : 0, x: mode === 'ACTIVE' ? 0 : -200 })
  const showHelperText_ = useIdle(3000)
  const [showHelperText, setShowHelperText] = useState(false)

  useEffect(() => {
    if (!showHelperText_) {
      setTimeout(() => {
        setShowHelperText(showHelperText_)
      }, 1000)
    } else {
      setShowHelperText(showHelperText_)
    }
  }, [showHelperText_])

  return (
    <animated.div
      className='absolute left-0 top-0 bottom-0 p-12 z-50 flex items-center'
      style={{
        opacity: revealProps.opacity,
        transform: interpolate([revealProps.x as any], (x) => `translate3d(${x}px, 0px, 0)`),
      }}
    >
      <div
        className='rounded-lg shadow-xl border bg-white border-gray-200 relative flex flex-col justify-between p-1'
        style={{ height: '55%', maxHeight: 700 }}
      >
        <div
          className='absolute my-auto text-gray-500 text-xl transition-opacity duration-500 flex items-center'
          style={{ left: 180, width: 300 * bodyScale, opacity: showHelperText ? 1 : 0 }}
        >
          <svg className='w-12 h-12 mr-4 arrow' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
              clipRule='evenodd'
            />
          </svg>
          <div>Drag one of the dressings to the body</div>
        </div>
        {springs.map(({ x, y, scale, opacity }, i) => {
          const bandaid = bandAids[i]
          return (
            <div key={bandaid.id} className='relative' style={{ cursor: 'grab', height: '24%' }}>
              <div className='absolute inset-0 pointer-events-none z-0 opacity-50 flex items-center justify-center'>
                <div className='absolute inset-0 border border-gray-400 border-dashed rounded-lg m-1' />
                <img className='max-w-full max-h-full' src={bandaid.image} alt='bandaid' />
              </div>
              <animated.div
                {...bind(i)}
                className='h-full flex items-center justify-center'
                style={{
                  opacity: opacity,
                  width: window.innerHeight * 0.55 * 0.2,
                  transformOrigin: '50% 50%',
                  transform: interpolate([x, y, scale], (x, y, s) => `translate3d(${x}px, ${y}px, 0) scale(${s})`),
                  touchAction: 'none',
                }}
              >
                <img className='max-w-full max-h-full pointer-events-none' src={bandaid.image} alt='bandaid' />
              </animated.div>
            </div>
          )
        })}
      </div>
    </animated.div>
  )
}

export default BandAidSelectorPanel

import Button from 'components/Button'
import LinkWithQuery from 'components/LinkWithQuery'
import { bandAids } from 'providers/bandAids'
import React, { FC } from 'react'

const DiscoverRoute: FC = () => {
  const bandAidBoxes = bandAids.map((bandAid) => (
    <a key={bandAid.id} href={bandAid.url} target='_blank' rel='noopener noreferrer'>
      <div key={bandAid.id} className='flex-1 text-center flex flex-col items-center mx-4'>
        <img src={bandAid.image} alt='Mepilex' className='w-24' />
        <h2 className='text-grey'>{bandAid.name}</h2>
      </div>
    </a>
  ))

  return (
    <div className='min-h-screen flex flex-col bg-white'>
      <div className='flex-1 flex-center flex-col p-8 pt-28' style={{ minHeight: '50vh' }}>
        <div className='max-w-6xl w-full flex flex-col text-center'>
          <h1 className='font-normal leading-tight text-3xl xl:text-5xl'>Discover our recommended pressure ulcer prevention</h1>
          <p className='text-xl text-gray-500 my-8'>
            From proven prevention to effective treatment, a comprehensive solution for your pressure ulcer needs.
          </p>
          <a className='mx-auto' href='https://www.molnlycke.com/wound-types/pressure-ulcers/' target='_blank' rel='noopener noreferrer'>
            <Button size='xl'>Visit molnlycke.com</Button>
          </a>
        </div>
      </div>
      <div className='bg-gray-100 flex-1 flex-center p-8' style={{ minHeight: '50vh' }}>
        <div className='max-w-6xl w-full flex flex-col items-center text-center'>
          <h1 className='font-normal leading-tight text-3xl xl:text-5xl'>Read more about our products</h1>
          <div className='flex w-full mt-4 max-w-2xl'>{bandAidBoxes}</div>
          <div className='flex mt-12'>
            <LinkWithQuery to='/tool/introduction'>
              <Button variant='secondary' size='xl'>
                Retry the tool
              </Button>
            </LinkWithQuery>
            <div className='w-4' />
            <LinkWithQuery to='/'>
              <Button variant='secondary' size='xl'>
                Back to start
              </Button>
            </LinkWithQuery>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverRoute

import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalStorage, useThrottle, useWindowSize } from 'react-use'
import { BandAid, bandAids as bandAidsData } from './bandAids'
import { BandAidDropzone, dropzones } from './dropzones'
interface BandAidProviderValue {
  mode: 'INTRODUCTION' | 'ACTIVE' | 'DISPLAY_RESULT' | null
  bodyScale: number
  bodyRotation: 'FRONT' | 'BACK'
  bodyContainerRef: any
  bandAids: BandAid[]
  bandAidDropzones: BandAidDropzone[]
  showPressurePoints: boolean
  bandAidPlacement: { dropzone: BandAidDropzone; bandAid: BandAid } | null
  placeBandAid: (bandAidId: string, dropzoneId: string) => void
  deleteBandAidPlacement: (dropzoneId: string) => void
  closeActiveBandAidPlacement: () => void
  setBodyRotation: (rotation: 'FRONT' | 'BACK') => void
  setBodyContainerRef: (ref: any) => void
  setMode: (mode: 'INTRODUCTION' | 'ACTIVE' | 'DISPLAY_RESULT') => void
  setShowPressurePoints: (show: boolean) => void
  reset: () => void
}

const BandAidContext = createContext<BandAidProviderValue>({
  mode: 'INTRODUCTION',
  bodyScale: 1,
  bodyRotation: 'FRONT',
  bodyContainerRef: null,
  bandAids: [],
  bandAidDropzones: [],
  showPressurePoints: false,
  bandAidPlacement: null,
  placeBandAid: () => null,
  deleteBandAidPlacement: () => null,
  closeActiveBandAidPlacement: () => null,
  setBodyRotation: () => null,
  setBodyContainerRef: () => null,
  setMode: () => null,
  setShowPressurePoints: () => null,
  reset: () => null,
})

const BandAidProvider: FC = ({ children }) => {
  const [mode, setMode] = useState<'INTRODUCTION' | 'ACTIVE' | 'DISPLAY_RESULT' | null>(null)
  const [bodyContainerRef, setBodyContainerRef] = useState(null)
  const [bodyScale, setBodyScale] = useState(1)
  const [bodyRotation, setBodyRotation] = useState<'FRONT' | 'BACK'>('FRONT')
  const [showPressurePoints, setShowPressurePoints] = useState(false)

  const [placementSession, setPlacementSession] = useLocalStorage<{ [dropzoneId: string]: string | null }>('session', {})

  const location = useLocation()
  useEffect(() => {
    if (location.pathname.indexOf('/tool') === -1) {
      setMode(null)
    }
  }, [location.pathname])

  const { height } = useWindowSize()
  const windowHeight = useThrottle(height, 1000)

  useEffect(() => {
    setBodyScale(((1 * windowHeight) / 1200) * 0.95)
  }, [windowHeight])

  const [bandAids] = useState<BandAid[]>(() => [...bandAidsData])

  const [bandAidDropzones, setBandAidDropzones] = useState<BandAidDropzone[]>(() => {
    return [...dropzones].map((d) => ({ ...d, placedBandAidId: placementSession?.[d.id] || undefined }))
  })

  const reset = () => {
    setPlacementSession({})
    setBandAidDropzones([...dropzones])
  }

  const [bandAidPlacement, setBandAidPlacement] = useState<{ dropzone: BandAidDropzone; bandAid: BandAid } | null>(null)

  const placeBandAid = (bandAidId: string, dropzoneId: string) => {
    const bandAid = bandAids.find((b) => b.id === bandAidId)
    const dropzone = bandAidDropzones.find((d) => d.id === dropzoneId)
    if (bandAid && dropzone) {
      setBandAidPlacement({ dropzone, bandAid })
      setPlacementSession({ ...placementSession, [dropzoneId]: bandAid.id })
      setBandAidDropzones(
        bandAidDropzones.map((dropzone) => {
          if (dropzone.id === dropzoneId) {
            return { ...dropzone, placedBandAidId: bandAidId }
          } else {
            return dropzone
          }
        })
      )
    }
  }

  const deleteBandAidPlacement = (dropzoneId: string) => {
    const newPlacementSession = { ...placementSession }
    delete newPlacementSession[dropzoneId]
    setPlacementSession(newPlacementSession)

    setBandAidDropzones(
      bandAidDropzones.map((dropzone) => ({
        ...dropzone,
        placedBandAidId: dropzoneId === dropzone.id ? undefined : dropzone.placedBandAidId,
      }))
    )
    setMode('ACTIVE')
  }

  const closeActiveBandAidPlacement = () => setBandAidPlacement(null)

  return (
    <BandAidContext.Provider
      value={{
        mode,
        bodyScale,
        bodyRotation,
        bodyContainerRef,
        showPressurePoints,
        setShowPressurePoints,
        bandAids,
        bandAidDropzones,
        setBodyRotation,
        placeBandAid,
        deleteBandAidPlacement,
        closeActiveBandAidPlacement,
        setBodyContainerRef,
        setMode,
        bandAidPlacement,
        reset,
      }}
    >
      {children}
    </BandAidContext.Provider>
  )
}

const useBandAidData = () => useContext(BandAidContext)

export { BandAidProvider, BandAidContext, useBandAidData }

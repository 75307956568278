import TextArea from 'components/TextArea'
import React, { ChangeEvent, createContext, FC, useContext, useEffect, useState } from 'react'
import { animated, interpolate, useSpring } from 'react-spring'
import { useThrottleFn } from 'react-use'

interface NotesSlideOverProviderValue {
  open: () => void
}

const NotesSlideOverContext = createContext<NotesSlideOverProviderValue>({
  open: () => null,
})

const NotesSlideOverProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const revealProps = useSpring({ opacity: open ? 1 : 0, x: open ? 0 : 500 })

  const [notes, setNotes] = useState(() => localStorage.getItem('notes') || '')

  useThrottleFn((notes: string) => localStorage.setItem('notes', notes || ''), 1000, [notes])

  const handleChangeNotes = (e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)

  useEffect(() => {
    if (open) {
      setNotes(localStorage.getItem('notes') || '')
    }
  }, [open])

  return (
    <NotesSlideOverContext.Provider
      value={{
        open: handleOpen,
      }}
    >
      {children}
      <div
        className={`fixed inset-0 ${!open && 'pointer-events-none'} bg-black transition-opacity duration-500`}
        style={{ opacity: open ? 0.3 : 0, zIndex: 10000 }}
        onClick={handleClose}
      />
      <animated.div
        className={`fixed top-0 bottom-0 p-8 right-0 overflow-y-scroll bg-white shadow ${!open && 'pointer-events-none'}`}
        style={{
          zIndex: 10001,
          width: 500,
          transform: interpolate([revealProps.x], (x) => `translate3d(${x}px, 0px, 0)`),
        }}
      >
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-bold text-gray-700'>Notes</h2>
          <button onClick={handleClose} className='w-8 h-8 text-gray-500 flex-center rounded-full border-gray-500 border'>
            <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>

        <TextArea value={notes} onChange={handleChangeNotes} placeholder='Add notes here...' />
      </animated.div>
    </NotesSlideOverContext.Provider>
  )
}

const useNoteSlideOver = () => useContext(NotesSlideOverContext)

export { NotesSlideOverProvider, NotesSlideOverContext, useNoteSlideOver }

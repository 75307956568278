import React, { ReactNode } from 'react'

export interface BandAid {
  id: string
  name: ReactNode
  description: ReactNode
  image: string
  url: string
}

export const bandAids: BandAid[] = [
  {
    id: 'mepilex_border_flex',
    name: (
      <>
        Mepilex<sup>®</sup> Border Flex
      </>
    ),
    description: (
      <>
        Mepilex<sup>®</sup> Border Flex with Flex Technology is designed to conform and stay on and protects areas other than the sacrum and
        heel from multidirectional forces that can cause pressure ulcers
      </>
    ),
    image: process.env.PUBLIC_URL + '/images/Mepilex_Border_Flex.png',
    url: 'https://www.molnlycke.com/products-solutions/mepilex-border-flex/',
  },
  {
    id: 'mepilex_border_flex_oval',
    name: (
      <>
        Mepilex<sup>®</sup> Border Flex Oval
      </>
    ),
    description: (
      <>
        Mepilex<sup>®</sup> Border Flex Oval with Flex Technology is designed to conform and stay on and protects areas other than the
        sacrum and heel from multidirectional forces that can cause pressure ulcers
      </>
    ),
    image: process.env.PUBLIC_URL + '/images/Mepilex_Border_Flex_Oval.png',
    url: 'https://www.molnlycke.com/products-solutions/mepilex-border-flex/',
  },
  {
    id: 'mepilex_border_sacrum',
    name: (
      <>
        Mepilex<sup>®</sup> Border Sacrum
      </>
    ),
    description: (
      <>
        Mepilex<sup>®</sup> Border Sacrum with Deep Defense<sup>®</sup> technology protects the sacral area from the forces that can cause
        pressure injuries when a patient slides down in bed
      </>
    ),
    image: process.env.PUBLIC_URL + '/images/Mepilex_Border_Sacrum.png',
    url: 'https://www.molnlycke.com/products-solutions/mepilex-border-sacrum/',
  },
  {
    id: 'mepilex_border_heel',
    name: (
      <>
        Mepilex<sup>®</sup> Border Heel
      </>
    ),
    description: (
      <>
        Mepilex<sup>®</sup> Border Heel with Deep Defense<sup>®</sup> technology protects the heels from the forces that can cause pressure
        injuries when a patient slides down in bed.
      </>
    ),
    image: process.env.PUBLIC_URL + '/images/Mepilex_Border_Heel.png',
    url: 'https://www.molnlycke.com/products-solutions/mepilex-border-heel/',
  },
]

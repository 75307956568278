import React, { createContext, FC, useContext, useMemo } from 'react'
import { useSearchParam } from 'react-use'

export enum AppType {
  Default = 'Default',
  Exhibition = 'Exhibition',
}

const AppTypeContext = createContext<AppType>(AppType.Default)

const AppTypeProvider: FC = ({ children }) => {
  const typeFromSearchParams = useSearchParam('type')

  const appType = useMemo<AppType>(() => {
    if (typeFromSearchParams === 'exhibition') {
      return AppType.Exhibition
    } else {
      return AppType.Default
    }
  }, [typeFromSearchParams])

  return <AppTypeContext.Provider value={appType}>{children}</AppTypeContext.Provider>
}

const useAppType = () => useContext(AppTypeContext)

export { AppTypeProvider, AppTypeContext, useAppType }

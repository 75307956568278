import React, { FC } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

const LinkWithQuery: FC<LinkProps> = ({ children, to, ...props }) => {
  const { search } = useLocation()

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  )
}

export default LinkWithQuery

import { AppType, useAppType } from 'providers/AppTypeProvider'
import { useBandAidData } from 'providers/BandAidProvider'
import React, { FC, useMemo, useRef } from 'react'
import { animated, interpolate, useSpring } from 'react-spring'
import { useClickAway } from 'react-use'
import Button from './Button'

const BandAidPlacementInformation: FC = () => {
  const { bodyScale, bandAidPlacement, closeActiveBandAidPlacement, bandAids } = useBandAidData()

  const revealProps = useSpring({ opacity: bandAidPlacement ? 1 : 0, y: bandAidPlacement ? 0 : 100 })

  const ref = useRef(null)
  useClickAway(ref, () => {
    if (Boolean(bandAidPlacement)) {
      closeActiveBandAidPlacement()
    }
  })

  const correctPlacement = useMemo(() => {
    if (bandAidPlacement) {
      return bandAidPlacement.dropzone.compatibleBandAidIds.indexOf(bandAidPlacement.bandAid.id) !== -1
    } else {
      return false
    }
  }, [bandAidPlacement])

  const appType = useAppType()

  return (
    <animated.div
      ref={ref}
      style={{
        left: `calc(50% + ${(350 / 2) * bodyScale + 20}px)`,
        zIndex: 10000,
        opacity: revealProps.opacity,
        transform: interpolate([revealProps.y], (y) => `translate3d(0px, ${y}px, 0)`),
      }}
      className='fixed w-1/3 bg-white shadow-xl p-4'
    >
      {bandAidPlacement && (
        <>
          <div className='flex justify-between items-center mb-2'>
            <h2 className={`${correctPlacement ? 'text-primary-green' : 'text-yellow-400'} font-semibold text-3xl`}>
              {correctPlacement && 'Perfect fit'}
              {!correctPlacement && 'There are better options'}
            </h2>
            <button onClick={closeActiveBandAidPlacement} className='w-8 h-8 flex-center text-gray-500 border border-gray-500 rounded-full'>
              <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
          <div className={`${correctPlacement ? 'text-primary-green' : 'text-gray-500'} font-medium text-lg`}>
            {!correctPlacement && <>{bandAidPlacement.bandAid.name} is not shaped to fit this specific area</>}
            {correctPlacement && (
              <>
                {bandAidPlacement.bandAid.name}
                <div className='prose prose-base mt-2 text-gray-500 mb-4'>
                  <p>{bandAidPlacement?.bandAid?.description}</p>
                </div>
                {bandAidPlacement.dropzone.compatibleBandAidIds.length > 1 && (
                  <>
                    <p className='text-gray-500 text-base'>You can also use</p>
                    <div className='flex'>
                      {bandAidPlacement.dropzone.compatibleBandAidIds
                        .filter((id) => id !== bandAidPlacement.bandAid.id)
                        .map((id) => {
                          const bandAid = bandAids.find((b) => b.id === id)
                          if (bandAid) {
                            return (
                              <div key={bandAid.id} className='flex-center text-center flex-col w-32 px-2'>
                                <img className='w-full my-0' src={bandAid.image} alt='Alternative band aid' />
                                <div className='text-grey font-semibold text-xs'>{bandAid.name}</div>
                              </div>
                            )
                          }

                          return ''
                        })}
                    </div>
                  </>
                )}
                <div className='flex space-x-4 mt-6'>
                  <Button onClick={closeActiveBandAidPlacement} size='xl'>
                    Next dressing
                  </Button>
                  {appType === AppType.Default && (
                    <a href='https://www.molnlycke.com/wound-types/pressure-ulcers/' target='_blank' rel='noopener noreferrer'>
                      <Button variant='third' size='xl'>
                        Read more
                      </Button>
                    </a>
                  )}
                </div>
              </>
            )}
            {!correctPlacement && (
              <Button onClick={closeActiveBandAidPlacement} className='mt-4' size='xl'>
                Next dressing
              </Button>
            )}
          </div>
        </>
      )}
    </animated.div>
  )
}

export default BandAidPlacementInformation

import Button from 'components/Button'
import LinkWithQuery from 'components/LinkWithQuery'
import { useBandAidData } from 'providers/BandAidProvider'
import React, { FC, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

interface Props extends RouteComponentProps<void> {}

const StartRoute: FC<Props> = ({ history }) => {
  const [placementSession] = useLocalStorage<{ [dropzoneId: string]: string | null }>('session', {})
  const { reset } = useBandAidData()

  const previousSession = useMemo(() => Object.keys(placementSession || {}).length !== 0, [placementSession])

  const { search } = useLocation()
  const startNewSession = () => {
    reset()
    history.push('/tool/intruduction' + search)
  }

  return (
    <div
      className='flex min-h-screen bg-cover bg-center relative'
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/start_bg.jpg'})` }}
    >
      <div className='opacity-50 bg-white absolute inset-0 z-0 block lg:hidden' />
      <div className='flex-1 hidden lg:block' />
      <div className='flex-1 flex items-center justify-center lg:justify-start z-10'>
        <div className='p-4 ml-12 max-w-2xl text-center lg:text-left'>
          <h1 className='tracking-tight text-5xl leading-tight'>Let’s stop pressure ulcers</h1>
          <div className='prose prose-lg text-xl text-gray-500 mb-6 lg:max-w-none lg:mb-0 mt-4'>
            <p>
              Raising awareness, sharing information and talking about pressure ulcers helps bring awareness to the problem. Mölnlycke has
              created this tool to help you select the appropriate dressing to help protect the anatomic areas most at risk of developing
              pressure ulcers.
            </p>
          </div>
          <div className='flex flex-col mt-12'>
            {!previousSession && (
              <LinkWithQuery to='/tool/introduction' className='flex-1'>
                <Button className='flex-1 w-full max-w-xs' size='xl'>
                  Use the tool
                </Button>
              </LinkWithQuery>
            )}

            {previousSession && (
              <>
                <LinkWithQuery to='/tool/introduction' className='flex-1'>
                  <Button className='flex-1 w-full max-w-xs' size='xl'>
                    Continue where I left off
                  </Button>
                </LinkWithQuery>
                <Button onClick={startNewSession} variant='secondary' className='flex-1 w-full max-w-xs mt-4' size='xl'>
                  Clear all selections
                </Button>
              </>
            )}

            <LinkWithQuery to='/recommended' className='flex-1'>
              <Button className='flex-1 w-full mt-4 max-w-xs' size='xl' variant='secondary'>
                Recommended dressing uses
              </Button>
            </LinkWithQuery>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartRoute

export interface BandAidDropzone {
  id: string
  name: string
  position: { x: number; y: number }
  side: 'FRONT' | 'BACK'
  compatibleBandAidIds: string[]
  placedBandAidId?: string
}

export const dropzones: BandAidDropzone[] = [
  {
    id: 'forehead',
    name: 'Forehead',
    position: { x: 146, y: 0 },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'chin',
    name: 'Chin',
    position: {
      x: 146,
      y: 100,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'chest_left',
    name: 'Chest',
    position: {
      x: 90,
      y: 220,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'chest_right',
    name: 'Chest',
    position: {
      x: 200,
      y: 220,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'hip_right',
    name: 'Hip',
    position: {
      x: 200,
      y: 390,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'hip_left',
    name: 'Hip',
    position: {
      x: 100,
      y: 390,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'knee_right',
    name: 'Knee',
    position: {
      x: 220,
      y: 710,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'knee_left',
    name: 'Knee',
    position: {
      x: 90,
      y: 710,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'wrist_right',
    name: 'Wrist',
    position: {
      x: 225,
      y: 950,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'wrist_left',
    name: 'Wrist',
    position: {
      x: 90,
      y: 950,
    },
    side: 'FRONT',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'occiput',
    name: 'Occiput',
    position: { x: 143, y: 35 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'shoulder_left',
    name: 'Shouler',
    position: { x: 95, y: 200 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'shoulder_right',
    name: 'Shouler',
    position: { x: 195, y: 200 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'elbow_left',
    name: 'Elbow',
    position: { x: 17, y: 350 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'elbow_right',
    name: 'Elbow',
    position: { x: 280, y: 350 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_flex_oval', 'mepilex_border_flex'],
  },
  {
    id: 'lumbar_spine',
    name: 'Lumbar spine',
    position: { x: 148, y: 425 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_sacrum'],
  },
  {
    id: 'heel_left',
    name: 'Heel',
    position: { x: 85, y: 990 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_heel'],
  },
  {
    id: 'heel_right',
    name: 'Heel',
    position: { x: 225, y: 990 },
    side: 'BACK',
    compatibleBandAidIds: ['mepilex_border_heel'],
  },
]
